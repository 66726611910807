<template>
  <modal name="tpa-game-modal"
         :adaptive="true"
         height="auto"
         class="modal"
         scrollable
         @before-open="beforeOpen"
         @before-close="beforeClose">
    <!-- Modal header -->
    <div class="modal-header">
      <label class="modal-header-title">
        {{ title | translate }}
      </label>
      <label class="modal-header-close"
             @click="$modal.hide('tpa-game-modal')">
        <span class="g-icon g-icon-close-a" />
      </label>
    </div>
    <!-- Modal content -->
    <div class="modal-content">
      <v-container fluid
                   class="pa-0">
        <v-row dense>
          <v-col cols="6"
                 data-app>
            <v-select v-model="game.aggregator"
                      :items="parsedAggregators"
                      :disabled="isEditMode"
                      :rules="[handleRequiredComboboxValue]"
                      :label="'tpagames_aggregator' | translate"
                      outlined
                      class="rm-border-radius" />
          </v-col>
          <v-col cols="6"
                 data-app>
            <v-select v-model="game.provider"
                      :items="parsedProviders"
                      :disabled="isEditMode"
                      :rules="[handleRequiredComboboxValue]"
                      :label="'tpagames_provider' | translate"
                      outlined
                      class="rm-border-radius" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6"
                 data-app>
            <v-select v-model="game.type"
                      :items="parsedTypes"
                      :rules="[handleRequiredComboboxValue]"
                      :label="'tpagames_type' | translate"
                      outlined
                      class="rm-border-radius" />
          </v-col>
          <v-col cols="6"
                 data-app>
            <v-select v-model="game.category"
                      :items="parsedCategories"
                      :rules="[handleRequiredComboboxValue]"
                      :label="'tpagames_category' | translate"
                      outlined
                      class="rm-border-radius" />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="6">
            <v-text-field key="title"
                          v-model="game.title"
                          v-validate="'required'"
                          :label="$options.filters.translate('tpa_game_modal_title')"
                          :disabled="isEditMode"
                          name="title"
                          outlined
                          :error="errors.first('title')"
                          class="rm-border-radius" />
          </v-col>
          <v-col cols="6">
            <v-text-field key="code"
                          v-model="game.code"
                          v-validate="'required'"
                          :label="$options.filters.translate('tpa_game_modal_code')"
                          name="code"
                          outlined
                          :error="errors.first('code')"
                          class="rm-border-radius" />
          </v-col>
        </v-row>
        <modal-input key="image"
                     v-model="game.image"
                     v-validate="'required'"
                     :label="$options.filters.translate('tpa_game_modal_image_url')"
                     name="image"
                     :error="errors.first('image')" />
        <modal-textarea key="description"
                        v-model="game.description"
                        class="modal-input"
                        :label="$options.filters.translate('tpa_game_modal_description')"
                        name="description" />
        <div class="game-items">
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[game.demo ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="game.demo = !game.demo" />
                <span class="game-item-check-label">
                  {{ 'tpa_game_modal_demo_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[game.live ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="game.live = !game.live" />
                <span class="game-item-check-label">
                  {{ 'tpa_game_modal_live_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[game.desktop ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="game.desktop = !game.desktop" />
                <span class="game-item-check-label">
                  {{ 'tpa_game_modal_desktop_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[game.mobile ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="game.mobile = !game.mobile" />
                <span class="game-item-check-label">
                  {{ 'tpa_game_modal_mobile_label' | translate }}
                </span>
              </span>
            </div>
          </div>
          <div class="game-item">
            <div class="game-item-title">
              <span class="game-item-text">
                <span class="g-icon"
                      :class="[game.fullscreen ? g_icon_checkbox_c : g_icon_checkbox]"
                      @click="game.fullscreen = !game.fullscreen" />
                <span class="game-item-check-label">
                  {{ 'tpa_game_modal_fullscreen_label' | translate }}
                </span>
              </span>
            </div>
          </div>
        </div>

        <div class="flex-row">
          <modal-input key="width"
                       v-model="game.width"
                       v-validate="'required'"
                       :label="$options.filters.translate('tpa_game_modal_width')"
                       name="width"
                       :error="errors.first('width')" />

          <modal-input key="height"
                       v-model="game.height"
                       v-validate="'required'"
                       :label="$options.filters.translate('tpa_game_modal_height')"
                       name="height"
                       :error="errors.first('height')" />
        </div>
      </v-container>
    </div>
    <!-- Modal buttons -->
    <div class="modal-footer">
      <button class="g-button g-button-earth game-modal-cancel-btn"
              @click="$modal.hide('tpa-game-modal')">
        <span class="g-button-title">
          {{ 'add_group_modal_cancel_adding' | translate }}
        </span>
      </button>
      <button class="g-button g-button-earth game-modal-add-btn"
              :disabled="submitDisabled"
              @click="submitGame">
        <span class="g-button-title">{{ isEditMode ? 'modal_edit' : 'modal_add' | translate }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _isEmpty from 'lodash/isEmpty';
import _trim from 'lodash/trim';
import { mapState, mapActions } from 'vuex';
import ModalInput from './shared/ModalInput';
import ModalTextarea from './shared/ModalTextarea';

export default {
  name: 'TpaGameModal',
  components: {
    ModalInput,
    ModalTextarea,
  },
  data: () => ({
    title: '',
    submitAction: '',
    game: {},
    previousGameCode: null,
    pristineGame: {},
    emptyGame: {
      aggregator: '',
      type: '',
      category: '',
      provider: '',
      title: '',
      code: '',
      image: '',
      description: '',
      demo: false,
      live: false,
      desktop: false,
      mobile: false,
      fullscreen: false,
      width: '',
      height: '',
    },
    g_icon_checkbox: 'g-icon-checkbox',
    g_icon_checkbox_c: 'g-icon-checkbox-c',
    parsedAggregators: [],
    parsedCategories: [],
    parsedProviders: [],
    parsedTypes: [],
    isEditMode: true,
  }),
  computed: {
    ...mapState({
      aggregators: (state) => state.tpagames.tpaAggregators,
      categories: (state) => state.tpagames.tpaCategories,
      providers: (state) => state.tpagames.tpaProviders,
      types: (state) => state.tpagames.tpaTypes,
    }),
    submitDisabled() {
      if (!this.game.aggregator || !this.game.category || !this.game.provider || !this.game.type) return true;
      return !!this.errors.items.length
      || _isEqual(this.pristineGame, this.game);
    },
  },
  methods: {
    ...mapActions([
      'handleErrorAction',
      'handleSuccessfulAction',
    ]),
    submitGame() {
      this.$validator.validate().then((valid) => {
        if (!valid) return;

        this.game.enabled = true;
        this.game.code = _trim(this.game.code);

        if (this.game.code === this.previousGameCode) {
          this.game.previousCode = null;
        } else {
          this.game.previousCode = this.previousGameCode;
        }

        this.$store.dispatch(this.submitAction, this.game).then(() => {
          this.handleSuccessfulAction(this.translateKey('tpa_game_registration_success'));
          this.$emit('onSuccessAction');
          this.$modal.hide('tpa-game-modal');
        }).catch((error) => {
          this.handleErrorAction(error);
        });
      });
    },
    async beforeOpen(event) {
      const { game, submitAction } = event.params;

      this.submitAction = submitAction;
      this.isEditMode = !_isEmpty(game);
      this.title = this.isEditMode ? 'edit_casino_game' : 'add_new_casino_game';
      this.game = this.isEditMode ? _cloneDeep(game) : _cloneDeep(this.emptyGame);
      this.previousGameCode = this.isEditMode ? game.code : null;
      if (this.isEditMode) {
        this.game.width += '';
        this.game.height += '';
      }
      this.pristineGame = _cloneDeep(this.game);
      this.$validator.resume();

      this.parsedAggregators = this.aggregators.map((item) => item.name);
      this.parsedCategories = this.categories.map((item) => item.name);
      this.parsedProviders = this.providers.map((item) => item.name);
      this.parsedTypes = this.types.map((item) => item.name);
    },
    beforeClose() {
      this.game = _cloneDeep(this.emptyGame);
      this.$validator.pause();
    },
    handleRequiredComboboxValue(value) {
      if (value instanceof Array && value.length === 0) {
        return this.translateKey('required_field');
      }
      return !!value || this.translateKey('required_field');
    },
    translateKey(key) {
      return this.$options.filters.translate(key);
    },
  },

};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_modal";
@import '../assets/theme/_clarity';

.rm-border-radius {
  border-radius: unset;
}

.flex-row {
  .g-input:first-child {
    margin-right: 1rem;
  }
}

.game-items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;

  .game-item {
    position: relative;
    border: thin solid $bg-300;

    .game-item-text {
      width: 100%;
      display: block;
      padding: 0.625em 0.625em 0.325em 0.625em;
    }

    .game-item-check-label {
      display: inline-block;
      font-size: $fs-200;
      font-weight: bold;
      padding-left: 1rem;
      vertical-align: top;
    }

    .g-icon {
      display: inline-block;
      color: $brand-lighter;
      cursor: pointer;
    }
  }
}
</style>
