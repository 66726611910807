<template>
  <div>
    <app-filter ref="appFilter"
                :filter-config="tpaFilterConfig"
                @filtersChanged="fetchData" />
    <div class="groups-wrapper fluid-container">
      <div v-if="!tpaGames || !tpaGames.data || !tpaGames.data.length"
           class="no-data">
        <h1 class="no-data-message">
          <label>
            {{ 'all_games_no_games' | translate }}
          </label>
        </h1>
      </div>

      <div class="g-table-andromeda">
        <div class="group-row head">
          <div class="group-data">
            <label class="group-head-label">{{ 'tpagames_aggregator' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'tpagames_provider' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'tpagames_category' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'tpagames_title' | translate }}</label>
          </div>
          <div class="group-data">
            <label class="group-head-label">{{ 'tpagames_code' | translate }}</label>
          </div>
        </div>
        <div class="group-rows">
          <div v-for="(game, index) in tpaGames.data"
               :key="index"
               class="group-row">
            <div class="group-data">
              <label class="group-label-head">{{ 'tpagames_aggregator' | translate }}</label>
              <label class="group-label">{{ game.aggregator }}</label>
            </div>

            <div class="group-data">
              <label class="group-label-head">{{ 'tpagames_provider' | translate }}</label>
              <label class="group-label">{{ game.provider }}</label>
            </div>

            <div class="group-data">
              <label class="group-label-head">{{ 'tpagames_category' | translate }}</label>
              <label class="group-label">{{ game.category }}</label>
            </div>

            <div class="group-data">
              <label class="group-label-head">{{ 'tpagames_title' | translate }}</label>
              <label class="group-label">{{ game.title }}</label>
            </div>

            <div class="group-data">
              <label class="group-label-head">{{ 'tpagames_code' | translate }}</label>
              <label class="group-label">{{ game.code }}</label>
            </div>

            <div class="group-data icon">
              <label class="group-label-head">{{ 'tpagames_edit' | translate }}</label>
              <label class="group-label">
                <span class="g-icon g-icon-edit"
                      @click="openModal($event, game)" />
              </label>
            </div>
          </div>
        </div>
        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <button class="g-button g-button-pluto tpa-mb-70"
                    v-bind="attrs"
                    v-on="on"
                    @click="openModal">
              <i class="pluto-icon g-icon g-icon-plus" />
            </button>
          </template>
          <span>{{ 'add_new_game' | translate }}</span>
        </v-tooltip>

        <v-tooltip left>
          <template #activator="{ on, attrs }">
            <button class="g-button g-button-pluto tpa-button-pluto"
                    v-bind="attrs"
                    v-on="on"
                    @click="openBulkModal">
              <i class="pluto-icon g-icon g-icon-darts" />
            </button>
          </template>
          <span>{{ 'bulk_import' | translate }}</span>
        </v-tooltip>
      </div>
      <div v-if="pagination.totalPages > 1"
           class="text-xs-center">
        <div>
          <v-container fluid>
            <v-row>
              <v-col cols="1"
                     class="pager"
                     data-app>
                <v-select
                  v-model="numberOfRows"
                  :items="rows"
                  solo />
              </v-col>

              <v-col cols="11">
                <v-pagination
                  v-model="pagination.page"
                  :circle="true"
                  :length="pagination.totalPages"
                  :total-visible="pagination.totalVisible"
                  @input="onPageChange" />
              </v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
    <tpa-game-modal :is-modal-open.sync="isModalOpen"
                    @onSuccessAction="handleSuccessAction" />
    <tpa-bulk-import-modal :is-modal-open.sync="isBulkModalOpen"
                           @onSuccessAction="handleSuccessAction" />
  </div>
</template>

<script>
import TpaGameModal from '@/components/TpaGameModal';
import TpaBulkImportModal from '@/components/TpaBulkImportModal';
import {
  mapState,
  mapGetters,
  mapActions,
} from 'vuex';
import AppFilter from '../components/AppFilter';

export default {
  name: 'TPAGames',
  components: {
    TpaGameModal,
    TpaBulkImportModal,
    AppFilter,
  },
  data() {
    return {
      isModalOpen: false,
      isBulkModalOpen: false,
      pagination: {
        page: 1,
        totalPages: 1,
        totalVisible: 5,
      },
      numberOfRows: 20,
      rows: [10, 15, 20, 25, 50, 100],
    };
  },
  computed: {
    ...mapState(['filterParams']),
    ...mapGetters(['tpaGames', 'tpaFilterConfig']),
  },

  watch: {
    numberOfRows: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
    'tpaGames.metadata.totalPages': {
      handler() {
        this.pagination.totalPages = (this.tpaGames && this.tpaGames.metadata) ? this.tpaGames.metadata.totalPages : 1;
        if (this.pagination.page > this.pagination.totalPages) {
          this.pagination.page = 1;
          this.pagination.totalPages = (this.tpaGames && this.tpaGames.metadata) ? this.tpaGames.metadata.totalPages : 1;
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    this.$store.dispatch('toggleLoader', true);
    Promise.all([
      this.$store.dispatch('getTPAAggregators'),
      this.$store.dispatch('getTPACategories'),
      this.$store.dispatch('getTPAProviders'),
      this.$store.dispatch('getTPATypes'),
    ]).then(() => {
      this.$refs.appFilter.setActiveFilters();
    });
  },

  methods: {
    ...mapActions([
      'getAllTPAGames',
    ]),
    handleSuccessAction() {
      this.fetchData();
    },
    async fetchData(params) {
      const parsedParams = Object.assign(params || this.filterParams, {
        page: this.pagination.page,
        limit: this.numberOfRows,
      });

      try {
        this.$store.dispatch('toggleLoader', true);

        if (parsedParams.search && parsedParams.search.length) {
          parsedParams.gameCode = parsedParams.search;
        } else {
          delete parsedParams.gameCode;
        }
        await this.getAllTPAGames(parsedParams);
      } finally {
        this.$store.dispatch('toggleLoader', false);
      }
    },
    openModal(ev, game = {}) {
      const submitAction = !Object.entries(game).length
        ? 'registerTPAGame'
        : 'updateTPAGame';
      this.$modal.show('tpa-game-modal', {
        game,
        submitAction,
      });
    },
    openBulkModal() {
      this.$modal.show('tpa-bulk-import-modal');
    },
    onPageChange(page) {
      this.pagination.page = page;
      this.fetchData();
    },
  },

};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_table";

.tpa-mb-70 {
  margin-bottom: 70px;
}

.group-row {
  @include breakpoint-regular {
    --grid-columns: repeat(5, 1fr);

  }
}

.group-row.head {
  @include breakpoint-regular {
    --grid-columns: repeat(5, 1fr);
  }
}

.group-data.icon {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include breakpoint-regular {
    position: absolute;
    top: 50%;
    right: 0.875em;
    transform: translateY(-50%);
  }
}
</style>
