<template>
  <modal name="tpa-bulk-import-modal"
         :adaptive="true"
         height="auto"
         class="modal"
         scrollable
         @before-open="beforeOpen">
    <!-- Modal header -->
    <div class="modal-header">
      <label class="modal-header-title">
        {{ 'tpa_bulk_import' | translate }}
      </label>
      <label class="modal-header-close"
             @click="$modal.hide('tpa-bulk-import-modal')">
        <span class="g-icon g-icon-close-a" />
      </label>
    </div>
    <!-- Modal content -->
    <div class="modal-content">
      <v-jsoneditor v-model="json"
                    :options="options"
                    :plus="false"
                    height="600px"
                    @error="onError" />

      <div v-if="messagesFromApi && messagesFromApi.length">
        <span v-for="(msg, index) in messagesFromApi"
              :key="index"
              class="span-validation"
              :class="{'validation-error-msg': msg.isError, 'validation-success-msg': !msg.isError}">
          {{ msg.message }}
        </span>
      </div>
    </div>
    <!-- Modal buttons -->
    <div class="modal-footer">
      <button class="g-button g-button-earth game-modal-cancel-btn"
              @click="$modal.hide('tpa-bulk-import-modal')">
        <span class="g-button-title">
          {{ 'add_group_modal_cancel_adding' | translate }}
        </span>
      </button>
      <button class="g-button g-button-earth game-modal-add-btn"
              :disabled="submitDisabled"
              @click="registerGame">
        <span class="g-button-title">{{ 'modal_save' | translate }}</span>
      </button>
    </div>
  </modal>
</template>

<script>
import { mapActions } from 'vuex';
import _cloneDeep from 'lodash/cloneDeep';
import _isEmpty from 'lodash/isEmpty';

import Ajv from 'ajv';
import VJsoneditor from 'v-jsoneditor';
import tpaGamesBulkImportSchema from '../utility/ajvSchemas';

const ajv = new Ajv({ schemaId: 'http://example.com/schemas/schema.json' });
const validate = ajv.compile(tpaGamesBulkImportSchema);

export default {
  name: 'TpaBulkImportModal',
  components: { VJsoneditor },
  data: () => ({
    emptyJson: [{
      code: '',
      aggregator: '',
      provider: '',
      category: '',
      type: '',
      title: '',
      description: '',
      image: '',
      demo: 0,
      active: 0,
      mobile: 0,
      live: 0,
      fullscreen: 0,
      width: 1200,
      height: 600,
    }],
    json: [{}],
    options: {
      mode: 'code',
      ajv: Ajv({ allErrors: true, verbose: true, nullable: false }),
      schema: tpaGamesBulkImportSchema,
    },
    messagesFromApi: [],
    submitDisabled: false,
  }),
  watch: {
    json: {
      deep: true,
      handler() {
        this.messagesFromApi = [];
        this.submitDisabled = !validate(this.json);
        if (this.json.length === 0) {
          this.submitDisabled = true;
        }
      },
    },
  },
  methods: {
    ...mapActions([
      'handleErrorAction',
      'handleSuccessfulAction',
      'registerMultipleTPAGames',
    ]),
    translateKey(key) {
      return this.$options.filters.translate(key);
    },
    isJsonValid() {
      const msg = {
        isError: true,
        message: this.$options.filters.translate('fill_required_fileds'),
      };
      this.messagesFromApi = [];

      this.json.forEach((obj) => {
        if (!obj.code || !obj.aggregator || !obj.provider || !obj.category
           || !obj.type || !obj.title || !obj.description || !obj.image) {
          this.messagesFromApi.push(msg);
        }
      });

      return _isEmpty(this.messagesFromApi);
    },
    handleMessagesFromApi(response) {
      const messages = [];
      const keys = Object.keys(response);
      let isError = false;
      // Errors are returned inside successful api response, so we have to parse them to get error messages.
      if (keys && keys.length) {
        keys.forEach((key) => {
          const obj = {
            message: `TPA game with code: ${response[key].code} successfully added.`,
            isError: false,
          };
          if (typeof response[key] !== 'object') {
            isError = true;
            obj.isError = true;
            obj.message = `${key} ${response[key]}`;
          }
          messages.push(obj);
        });
      }

      if (isError) {
        this.handleErrorAction({ response: { data: { message: this.translateKey('generic_error') } } });
        this.messagesFromApi = messages;
      } else {
        this.handleSuccessfulAction(this.translateKey('tpa_games_registration_success'));
        this.$emit('onSuccessAction');
        this.$modal.hide('tpa-bulk-import-modal');
      }
    },
    registerGame() {
      this.json.forEach((item) => {
        const game = item;
        game.enabled = true;
      });

      if (this.isJsonValid()) {
        this.registerMultipleTPAGames(this.json).then((response) => {
          this.handleMessagesFromApi(response.data);
        }).catch((error) => this.handleErrorAction(error));
      }
    },
    async beforeOpen() {
      this.json = _cloneDeep(this.emptyJson);
      this.messagesFromApi = [];
    },
    onError() {
      this.submitDisabled = true;
    },
  },

};
</script>

<style lang="scss" scoped>
@use "@/styles/components/_modal";

.modal-content {
  padding: 1rem;

  .span-validation {
    display: block;
    font-size: 14px;
  }

  .validation-error-msg{
    color: $negative;
  }

  .validation-success-msg{
    color: $positive;
  }
}
</style>
