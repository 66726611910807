<template>
  <div class="g-input g-input-io"
       :class="{ 'invalid': !!error }">
    <textarea class="g-input-textarea"
              rows="4"
              :name="name"
              :value="value"
              :type="type"
              :disabled="disabled"
              :readonly="readOnly"
              @blur="$emit('blur')"
              @focus="$emit('focus')"
              @input="$emit('input', $event.target.value)" />
    <div class="g-input-label">
      {{ label || translationKey | translate }}
    </div>
    <div v-show="!!error"
         class="g-input-validation">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalTextarea',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    translationKey: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },
};
</script>
