const tpaGamesBulkImportInnerSchema = {
  $id: 'http://example.com/schemas/schema.json',
  type: 'object',
  additionalProperties: false,
  required: ['code', 'aggregator', 'provider', 'category', 'type', 'title', 'description', 'image', 'demo', 'active', 'mobile', 'live', 'fullscreen', 'width', 'height'],
  properties: {
    code: { type: 'string' },
    aggregator: { type: 'string' },
    provider: { type: 'string' },
    category: { type: 'string' },
    type: { type: 'string' },
    title: { type: 'string' },
    description: { type: 'string' },
    image: { type: 'string' },
    demo: { type: 'number' },
    active: { type: 'number' },
    mobile: { type: 'number' },
    live: { type: 'number' },
    fullscreen: { type: 'number' },
    width: { type: 'number' },
    height: { type: 'number' },
  },
};

const tpaGamesBulkImportSchema = {
  $id: 'http://example.com/schemas/schema.json',
  type: 'array',
  additionalProperties: false,
  items: tpaGamesBulkImportInnerSchema,
};

export default tpaGamesBulkImportSchema;
